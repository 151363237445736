import React, { useCallback, useMemo, useState, useEffect } from "react";
import { navigate, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "../components/link";

import Layout from "../components/layout";
import Seo from "../components/seo";

import CheckIcon from "../icons/check-solid.svg";

const Pricing = ({ data: { hero, pricing }, location }) => {
  const allPricing = pricing.pricing;

  const setQueryPlatform = useCallback(
    (platform) => {
      const params = new URLSearchParams(location.search);
      if (platform === 1) {
        params.delete("platform");
      } else {
        params.set("platform", platform);
      }
      const qs = params.toString();
      // console.log("qs", qs);
      const to = (qs ? `?${qs}` : "") + (location.hash || "");
      navigate(to);
      setCurrentPlatform(platform);
    },
    [location]
  );

  const [currentPlatform, setCurrentPlatform] = useState(1);

  useEffect(() => {
    const queryPlatform = new URLSearchParams(location.search).get("platform");
    // console.log("queryPlatform: ", queryPlatform);
    if (queryPlatform !== "1" && ["2"].includes(queryPlatform)) {
      setCurrentPlatform(parseInt(queryPlatform));
    } else if (!queryPlatform) {
      setCurrentPlatform(1);
    }
  }, [location.search]);

  const tipologiaProdotto = useMemo(
    () => allPricing.filter((pricing) => pricing.platform === currentPlatform),
    [currentPlatform, allPricing]
  );

  return (
    <Layout>
      <Seo
        title={`Intranet 80.20: Confronta Starter e Enterprise`}
        description={`Piano Starter o Enterprise: è la tua prima esperienza o vuoi evolvere la tua intranet aziendale? Progettati entrambi su Liferay e Microsoft 365.`}
      />

      <div className="hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-md-7">
              <h1 className="hero__title">La soluzione giusta per la tua azienda.</h1>
              <div className="hero__claim">
                <p>
                  Ogni intranet è calata sulla realtà specifica dell'azienda - che sia alla prima
                  esperienza (Piano <strong>Starter</strong>) o intenda evolvere l'attuale (Piano{" "}
                  <strong>Enterprise</strong>) - sfruttando il back-office nativo delle piattaforme
                  di riferimento del mercato globale: <strong>Liferay</strong> e{" "}
                  <strong>Microsoft 365</strong>.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4 offset-md-1 col-xxl-5 offset-xxl-0">
              <GatsbyImage
                image={getImage(hero)}
                alt="img hero"
                objectPosition="center center"
                className="hero__img hero__img--confronta m-auto me-sm-0"
              />
            </div>
          </div>
        </div>
        <div className="hero__text-scroll hero__text-scroll--fulltop">
          <div className="text-scroll" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase confronta">Confronta</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase confronta">Confronta</span>
            </div>
          </div>
        </div>
      </div>

      <section id="platform" className="box-pricing">
        <div className="box-pricing box-pricing--sticky">
          <div className="box-pricing__row">
            <div className="container-lg">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    type="button"
                    className={`box-pricing__btn compare-click-tracking-ga${
                      currentPlatform === 1 ? " box-pricing__btn--active" : ""
                    }`}
                    onClick={() => setQueryPlatform(1)}
                    data-ga-id={`piattaforma liferay`}
                  >
                    <span className="d-block text-uppercase">Intranet 80.20</span> Liferay
                  </button>
                  <button
                    type="button"
                    className={`box-pricing__btn compare-click-tracking-ga${
                      currentPlatform === 2 ? " box-pricing__btn--active" : ""
                    }`}
                    data-ga-id={`piattaforma ms365`}
                    onClick={() => setQueryPlatform(2)}
                  >
                    <span className="d-block text-uppercase">Intranet 80.20</span> Microsoft 365
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-lg mt-2 mt-sm-4">
            <div className="row">
              <div className="col-12 col-xl-10 offset-xl-1">
                <div className="row">
                  <div className="col-2 offset-8 col-lg-3 offset-lg-6 col-xl-2 offset-xl-8 text-center">
                    <Link to="/starter/" className="btn-link text-nowrap">
                      <strong>
                        S<span className="d-none d-md-inline-block">tarter</span>
                      </strong>
                    </Link>
                  </div>
                  <div className="col-2 col-lg-3 col-xl-2 text-center">
                    <Link to="/enterprise/" className="btn-link btn-link--secondary text-nowrap">
                      <strong>
                        E<span className="d-none d-md-inline-block">nterprise</span>
                      </strong>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {tipologiaProdotto.map((item, i) => (
          <div key={i} className="box-pricing__row">
            <div className="container-lg">
              <div className="row">
                <div className="col-12 col-xl-10 offset-xl-1">
                  <div className="row">
                    <div className="col-12">
                      <div className="box-pricing__name">{item.name}</div>
                    </div>
                  </div>
                  {item.children && item.children.length && (
                    <div>
                      {item.children.map((child, j) => (
                        <div key={j} className="box-pricing__opt">
                          <div className="row">
                            <div className="col-8 col-lg-6 col-xl-8">{child.name}</div>
                            <div className="col-2 col-lg-3 col-xl-2 text-center">
                              {child.starter ? (
                                <span className="box-pricing__starter">
                                  <CheckIcon className="ico-svg" />
                                </span>
                              ) : (
                                <span className="text-uppercase">opt</span>
                              )}
                            </div>
                            <div className="col-2 col-lg-3 col-xl-2 text-center">
                              <span className="box-pricing__enterprise">
                                <CheckIcon className="ico-svg" />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="container-lg mt-4">
          <div className="row">
            <div className="col-12 col-xl-10 offset-xl-1">
              <div className="row">
                <div className="col-12 col-lg-3 offset-lg-6 col-xl-4 offset-xl-8 text-center">
                  <Link
                    to="/richiedi-demo/"
                    className="btn-link btn-link--secondary text-nowrap demo-click-tracking-ga"
                    data-ga-id="confronta demo button"
                  >
                    Richiedi <strong>demo</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero-pricing.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    pricing: pricingJson {
      pricing {
        platform
        name
        children {
          name
          starter
        }
      }
    }
  }
`;
